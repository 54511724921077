import 'reflect-metadata'; // DO NOT REMOVE THIS IMPORT
/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
/**
 * element.scrollIntoView() support for Safari.
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 * https://github.com/iamdustan/smoothscroll
 */
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

// https://github.com/aws-amplify/amplify-js/issues/678
(window as any).global = window;
